import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@components/form-elements/buttons/Button';
import { Microphone } from '@components/FileUpload/components/PreviewFile/icons/Microphone';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Wrapper } from '@components/Modal/UnlockJobModal/styles/Wrapper';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAPI } from '@hooks/useAPI';
import { UnlockChoiceOption } from './styles/UnlockChoiceOption';
import { ChoiceOptionIcon } from './icons/ChoiceOptionIcon';
import { BillingService, JobsService, PayService } from 'app/API';
import { TProps } from './types/TProps';
import { FileSection } from './styles/FileSection';
import { SummaryIcon } from './icons/SummaryIcon';
import { DownloadFileIcon } from './icons/DownloadFileIcon';
import { formatFileSize } from '@helpers/formatFileSize';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { EErrorMessages } from '@constants/errorMessages';
import { client } from '../../../http';
import { fakeDownloadClick } from '@helpers/jobLinkHelpers';

export function UnlockJobModal({ id, name, onSuccess, onCancel }: TProps) {
  const { closeModal, openModal } = useModalContext();
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [partialUnlockProcessed, setPartialUnlockProcessed] = useState(false);
  const [outputUrl, setOutputUrl] = useState('');
  const [summaryUrl, setSummaryUrl] = useState('');
  const [reporterNotes, setReporterNotes] = useState<string[]>([]);
  const [outputFileSize, setOutputFileSize] = useState(0);
  const [summaryFileSize, setSummaryFileSize] = useState(0);
  const [unlockPrice, setUnlockPrice] = useState(0);
  const [error, setError] = useState(false);

  const downloadReporterNotes = async (fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${id}/reporter-notes/${fileName}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(fileName));
  };

  const getDownloadUrls = async (interval?: any, loadingToastId?: string) => {
    const onError = () => {
      toast.error(EErrorMessages.DEFAULT);
      setIsLoading(false);
      setError(true);

      if (interval) {
        clearInterval(interval);
      }

      if (loadingToastId) {
        toast.remove(loadingToastId);
      }
    };

    let isSummaryCompleted = false;
    const onSuccess = (response: any) => {
      const {
        downloadOutputUrl,
        downloadSummaryUrl,
        downloadOutputFileSize,
        downloadSummaryFileSize,
        reporterNotes,
        error,
      } = response;

      if (error) {
        onError();
        isSummaryCompleted = true;
      }

      if (downloadOutputUrl && downloadSummaryUrl) {
        setOutputUrl(downloadOutputUrl);
        setSummaryUrl(downloadSummaryUrl);
        setOutputFileSize(downloadOutputFileSize);
        setSummaryFileSize(downloadSummaryFileSize);
        setReporterNotes(reporterNotes);
        setIsLoading(false);
        if (interval) {
          clearInterval(interval);
        }
        if (loadingToastId) {
          toast.remove(loadingToastId);
          toast.success('Reporter unlock has been successfully processed');
        }
        setError(false);
        isSummaryCompleted = true;
      }
    };

    await call(JobsService.getJobPartialUnlockedFiles({ id }), {
      onError,
      onSuccess,
    });

    return isSummaryCompleted;
  };

  useEffect(() => {
    setIsLoading(true);
    const getPrice = async () => {
      const priceData = await call(BillingService.calculateJobUnlockPricing({ jobId: id }));
      if (priceData?.result) {
        setUnlockPrice(Number(priceData?.result.amount));
        setPartialUnlockProcessed(priceData?.result.partialUnlocked);
      }
    };

    getPrice();

    const pullDownloadLinks = async () => {
      setIsLoading(true);
      const summaryCompleted = await getDownloadUrls();

      if (!summaryCompleted) {
        const loadingToastId = toast.loading('Waiting summary is completed...');
        const interval: NodeJS.Timeout = setInterval(async () => await getDownloadUrls(interval, loadingToastId), 5000);

        return () => {
          clearInterval(interval);
          setIsLoading(false);
        };
      }
    };

    if (partialUnlockProcessed) {
      pullDownloadLinks();
    } else {
      setIsLoading(false);
    }
  }, [partialUnlockProcessed]);

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Unlock The Job</h2>

      <UnlockChoiceOption
        onClick={() => {
          closeModal();
          openModal(ModalNamesEnum.UnlockJobFull, { id, name, onSuccess, onCancel });
        }}
      >
        <div>
          <div className="title">Full unlock</div>
          <div className="description">Get full access to the job</div>
          <div className="price">{formatCentsPrice(unlockPrice || 0)}</div>
        </div>
        <ChoiceOptionIcon />
      </UnlockChoiceOption>

      {partialUnlockProcessed ? (
        <div className="mt-2">
          {error ? (
            <div className="text-red-500 text-sm mt-2 w-max[100px]">
              <div>Something went wrong during getting summary.</div>
              <div>Please contact support.</div>
            </div>
          ) : (
            <>
              <FileSection>
                <SummaryIcon className="icon" />
                <div className="file-description">
                  <div>Court_Reporting_Summary.PDF</div>
                  <div>File size {formatFileSize(summaryFileSize)}</div>
                </div>
                <a href={summaryUrl} target="_blank" rel="noreferrer">
                  <DownloadFileIcon />
                </a>
              </FileSection>

              <FileSection className="mt-2">
                <Microphone className="icon" />
                <div className="file-description">
                  <div>Court_Reporting_Audio.MP3</div>
                  <div>File size {formatFileSize(outputFileSize)}</div>
                </div>
                <a href={outputUrl} target="_blank" rel="noreferrer">
                  <DownloadFileIcon />
                </a>
              </FileSection>

              {reporterNotes.map((fileName) => (
                <FileSection key={fileName} className="mt-2">
                  <SummaryIcon className="icon" />
                  <div className="file-description">
                    <div>{fileName}</div>
                  </div>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      downloadReporterNotes(fileName);
                    }}
                  >
                    <DownloadFileIcon />
                  </a>
                </FileSection>
              ))}
            </>
          )}
        </div>
      ) : (
        <UnlockChoiceOption
          className="mt-2"
          onClick={() => {
            call(PayService.partialCharge({ id }), {
              onError: (message: string) => toast.error(message),
              onSuccess: () => {
                setPartialUnlockProcessed(true);
              },
            });
          }}
        >
          <div>
            <div className="title">Reporter unlock</div>
            <div className="description">Get summary and audio access</div>
            <div className="price">$0.50</div>
          </div>
          <ChoiceOptionIcon />
        </UnlockChoiceOption>
      )}

      <div className="buttonWrapper self-end">
        <Button onClick={closeModal} variant="outlined">
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
}
